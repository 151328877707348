:root{
    font-size: 4;
}

html, body{
    height:100%;
}

.submenu{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.submenu-link:hover{
    color: white !important;
    border-left: 2px solid white !important;
    background-color: #396F48 !important;
}

.submenu-link{
    margin-left: 2px;
    outline: none;
}

.popup-content {
    margin: auto;
    background: #083909 !important;
    width: 50%;
    padding: 5px;
    border-radius: 5px;
}

.popup-arrow {
    color: #083909 !important;
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}



/* 
.submenu{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.submenu-container{
    width:100% !important;
}

.submenu-item{
    width: 100% !important;
}

.submenu-link{
    text-decoration: none !important;
    color: white !important; 
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100% !important;
}

.highlighter{
    width: 5px;
    height: 100%;
    background-color: white;
}

.submenu-link:hover{
    color: white !important;
    border-left: 2px solid white !important;
    background-color: #396F48 !important;
}

.my-popup-content{
    background-color: #083909 !important;
}

.my-popup-arrow{
    color: #083909 !important;
}

.user-button{
    background-color: #083909 !important;
    width: 20%;
} 
*/