
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
:root {
    --bs-body-font-family: 'Lato', sans-serif;
}

.ContentWrapper {
    padding:30px;
}

.content-bar-row {
    margin-left:0px !important;
    margin-right:0px !important;
    margin-bottom: 30px;
}

.user-button:hover {
    background-color: #5BC72B;
}